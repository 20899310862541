<template>
  <q-layout>
    <q-header class="bg-white" style="position: fixed; min-width: 800px">
      <m-Header :isnav="$route.meta.isnav" />
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer class="bg-white">
      <m-Footer />
    </q-footer>
    <q-dialog v-model="couponShow" no-backdrop-dismiss no-esc-dismiss>
      <div class="new-coupon">
        <img class="box" src="@/assets/coupon-box.png" />
        <div class="btn" @click="exchange">
          <img src="@/assets/receive-btn.png" />
          <p>{{ $t("common.get_now") }}</p>
        </div>
        <div class="info">
          <div class="title">{{ $t("common.reg_receive") }}</div>
          <div class="title">
            {{ $i18n.locale == "en" ? couponInfo.titleen : couponInfo.title }}
          </div>
          <div class="tip">{{ $t("common.no_restrictions") }}</div>
        </div>
        <div class="close">
          <q-icon name="close" @click="closeCoupon" class="cursor-pointer" />
        </div>
      </div>
    </q-dialog>
  </q-layout>
</template>

<script>
import mHeader from '@/components/Header'
import mFooter from '@/components/Footer'
import { getPrize } from '@/api/my'
import _ from 'lodash'
import store from './store'

export default {
  components: {
    mHeader,
    mFooter
  },
  data() {
    return {
      isnav: false,
      couponShow: false,
      couponInfo: '',
      viewerIndex: 0
    }
  },
  computed: {
    VIPdis() {
      return this.$store.state.VIPdis
    },
    isLoading() {
      return store.getters.isLoading
    },
    isStopLoading() {
      return store.state.global.isStopLoading
    }
  },
  watch: {
    VIPdis(val) {
      const item = _.find(val, { type: 12 })
      if (item) {
        this.couponShow = true
        this.couponInfo = item
      }
    },
    isLoading: {
      handler(val) {
        if (val && !this.isStopLoading) {
          this.$q.loading.show()
        } else {
          this.$q.loading.hide()
        }
      },
      immediate: true
    }
  },
  methods: {
    closeCoupon() {
      this.couponShow = false
    },
    // 兑换
    async exchange() {
      const res = await getPrize({
        VIPdiscountguid: this.couponInfo.guid,
        action: 'user_exchange'
      })
      if (res.status === 1) {
        this.$q.notify(this.$t('common.exchange_success'))
        this.couponShow = false
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/iconfont.css';

a {
  text-decoration: none;
  color: #000;
  display: block;
}

#app {
  min-width: 800px;
}

.blod {
  font-weight: bold;
}

.putitle {
  position: relative;
  width: 100%;
  height: 58px;
}

.putitle::before {
  content: '';
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: #cccccc;
  left: 0;
  top: 50%;
  margin-top: -0.05px;
}

.putitle>span {
  display: inline-block;
  padding: 0 10px;
  font-size: 20px;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &.left {
    left: 10%;
  }

  &.right {
    left: 90%;
  }
}

.extend-click {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: pointer;
  pointer-events: auto;
}

@media (min-width: 1440px) {

  .q-header .q-tab__content,
  .q-footer .q-tab__content {
    min-width: 0 !important;
  }
}

.viewer-title {
  display: none !important;
}

.new-coupon {
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: none !important;

  .close {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 40px;
    color: #fff;
  }

  .box {
    width: 100%;
    padding: 20px;
  }

  .btn {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    cursor: pointer;
    display: flex;

    img {
      width: 100%;
    }

    p {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      color: #78482f;
      width: 100%;
      text-align: center;
    }
  }

  .info {
    position: absolute;
    top: 15%;
    left: 20%;
    color: #885a3d;

    .title {
      font-size: 40px;
    }

    .tip {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}

#bsWXBox {
  width: 230px !important;
  height: 256px !important;
}

.bsTop {
  width: 218px !important;
}

.line-through {
  text-decoration: line-through;
  color: #666;
}

.no-wrap-txt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.liner-dashed {
  border-bottom: 1px dashed #000;
  margin: 5px 0 !important;
}
</style>
