<template>
<div class="header-cont">
  <header-top :isnav="isnav"></header-top>
  <header-main :isnav="isnav"></header-main>
</div>
</template>

<script>
import HeaderTop from './headers/HeaderTop.vue'
import HeaderMain from './headers/HeaderMain.vue'
export default {
  components: {
    HeaderTop,
    HeaderMain
  },
  props: {
    isnav: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
