<template>
  <div class="container">
    <div class="loginCon pucontainer">
      <h5>{{$t('login_mod.login_t')}}</h5>
      <q-form @submit="onSubmit">
        <div class="inputdiv" v-if="loginChange">
          <q-input
            autofocus
            maxlength="11"
            type="tel"
            v-model="loginForm.phone"
            :label="$t('login_mod.phone_t')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
          <p>
            {{$t('login_mod.ro_t')}}
            <span
              class="changet"
              @click="loginChange = false"
            >{{$t('login_mod.email_t')}}</span>
          </p>
        </div>
        <div class="inputdiv" v-else>
          <q-input
            autofocus
            maxlength="30"
            type="email"
            v-model="loginForm.email"
            :label="$t('login_mod.email_t')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_email'),
              (val) => emailRule(val) || $t('login_mod.email_format')
            ]"
          />
          <p>
            {{$t('login_mod.ro_t')}}
            <span
              class="changet"
              @click="loginChange = true"
            >{{$t('login_mod.phone_t')}}</span>
          </p>
        </div>
        <div class="inputdiv">
          <q-input
            type="password"
            maxlength="30"
            v-model="loginForm.pwd"
            :label="$t('login_mod.pwd_t')"
            :rules="[(val) => (val !== null && val !== '') || $t('login_mod.enter_pwd')]"
          />
          <div class="tdesc">
            <p>
              <q-checkbox size="xs" v-model="loginStatus" />
              {{$t('login_mod.keep_signed')}}
            </p>
            <p class="forget" @click="$router.push('/my/forget')">{{$t('login_mod.forgot_pwd')}}</p>
          </div>
        </div>
        <div class="btndiv">
          <q-btn type="submit" class="full-width" color="primary" :label="$t('login_mod.login_t')" />
        </div>
        <div class="btndiv">
          <q-btn
            class="full-width"
            color="primary"
            outline
            @click="$router.push('/Register')"
            :label="$t('login_mod.reg_t')"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { upDatedHead } from '@/common/upDatedHead'
import { phoneLogin, emailLogin } from '@/api/login'
import { phoneRule, emailRule } from '@/common/formRules'
export default {
  data () {
    return {
      loginStatus: true,
      loginChange: true, // true 为手机登录，false为邮箱登录
      loginForm: {
        phone: '',
        email: '',
        pwd: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      if (this.loginChange) {
        const res = await phoneLogin({
          mark: this.loginForm.phone,
          pwd: this.loginForm.pwd
        })
        if (res.status === 3) {
          upDatedHead() // 更新userinfo信息
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        const emal = this.loginForm.email.split('@')
        if (emal[1].toLowerCase() === 'qq.com') {
          emal[1] = 'qq.com'
        }
        const res = await emailLogin({
          mark: emal.join('@'),
          pwd: this.loginForm.pwd
        })
        if (res.status === 3) {
          upDatedHead() // 更新userinfo信息
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
    }
  },
  mounted () {
    if (this.$store.state.isLogin) {
      this.$router.push({
        path: '/'
      })
      this.$q.notify(this.$t('login_mod.p_out'))
    }
  },
  setup () {
    return {
      emailRule,
      phoneRule
    }
  }
}
</script>

<style lang="scss" scoped>
.loginCon {
  margin: 87px auto;
  h5 {
    text-align: center;
  }
  .inputdiv {
    width: 530px;
    margin: 10px auto;
    margin-top: 50px;
    p {
      margin-top: 5px;
      display: flex;
      align-items: center;
      .changet {
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        color: #000;
        font-size: 16px;
      }
    }
    .tdesc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p.forget {
        color: #999999;
        cursor: pointer;
      }
    }
  }
  .btndiv {
    width: 530px;
    margin: 10px auto;
  }
}
</style>
