import axios from '@/utils/request'
import qs from 'qs'

/* 获取用户信息 */
export const getInfoApi = () => {
  return axios.post('/common/init.ashx', qs.stringify())
}

// 会员初始化
export const memberInit = () => axios.post('/common/init.ashx')

// 获取积分
export const getPoints = () =>
  axios.post(
    '/common/integral.ashx',
    qs.stringify({
      action: 'user_integral'
    })
  )

// 获取余额
export const getBalance = (params) =>
  axios.post(
    '/common/balance.ashx',
    qs.stringify({
      action: 'user_balance',
      ...params
    })
  )

// 获取奖品
export const getPrize = (params) =>
  axios.post(
    '/common/VIPdiscount.ashx',
    qs.stringify({
      ...params
    })
  )

// 获取订单
export const getOrder = (params) =>
  axios.post(
    '/common/order.ashx',
    qs.stringify({
      ...params
    })
  )

/* 编辑用户信息 */
export const editInfoApi = (info) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 11,
      ...info
    })
  )
}

/* 收藏api */
export const wish = (params) => {
  return axios.post(
    '/common/wish.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 地址Api */
export const address = (params) => {
  return axios.post(
    '/common/shippingaddress.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 获取商品颜色尺码 */
export const usersaleApi = (param) => {
  return axios.post(
    '/common/usersale.ashx',
    qs.stringify({
      ...param
    })
  )
}
