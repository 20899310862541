import axios from '@/utils/request'
import qs from 'qs'

/* 手机登录 */
export const phoneLogin = (info) => {
  return axios.post(
    '/common/obtainCode2.ashx',
    qs.stringify({
      type: 102,
      ...info
    })
  )
}
/* 邮箱登录 */
export const emailLogin = (info) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 10,
      ...info
    })
  )
}
/* 手机注册 */
export const phoneReg = (info) => {
  return axios.post(
    '/common/obtainCode2.ashx',
    qs.stringify({
      type: 12,
      ...info
    })
  )
}
/* 邮箱注册 */
export const emailReg = (info) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 1,
      ...info
    })
  )
}
/* 手机注册修改手机号码 */
export const changePhoneApi = (params) => {
  return axios.post(
    '/common/obtainCode2.ashx',
    qs.stringify({
      type: 52,
      ...params
    })
  )
}
/* 手机号修改密码 */
export const phoneChangePwdApi = (params) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 22,
      ...params
    })
  )
}
/* 邮箱修改密码 */
export const emailChangePwdApi = (params) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 23,
      ...params
    })
  )
}
/* 绑定邮箱 */
export const emailBindApi = (params) => {
  return axios.post(
    '/common/obtainCode2.ashx',
    qs.stringify({
      type: 42,
      ...params
    })
  )
}
/* 绑定手机号 */
export const phoneBindApi = (params) => {
  return axios.post(
    '/common/obtainCode2.ashx',
    qs.stringify({
      type: 43,
      ...params
    })
  )
}
/* 邮箱绑定手机 */
export const emailBindPhoneApi = (params) => {
  return axios.post(
    '/common/obtainCode.ashx',
    qs.stringify({
      type: 4,
      ...params
    })
  )
}

/* 退出登录 */
export const loginOut = () => {
  return axios.post('/common/obtainCode.ashx', qs.stringify({
    type: 20,
    mark: 1
  }))
}
