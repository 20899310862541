import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
// 组件库中文网 http://www.quasarchs.com/
// 官网 https://quasar.dev/
import { switchLink } from '@/utils/common'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { currency2 } from '@/api/common'
import i18n from './language'

VueViewer.setDefaults({
  transition: false
})

/* 获取完导航信息在登录 */
const app = createApp(App)
app
  .use(Quasar, quasarUserOptions)
  .use(VueViewer)
  .use(store)
  .use(router)
  .use(i18n)
const obj = { id: '1', title: '男士' }
const pageType = JSON.parse(localStorage.getItem('pageType')) || obj
store.commit('setPageType', pageType)

if (process.env.NODE_ENV === 'production') {
  // 监听窗口大小，小于800px跳转手机端链接
  switchLink()
  // 添加分享组件
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://static.bshare.cn/b/buttonLite.js#uuid=4349e0ab-c185-492e-a964-4cde09510dc2&style=-1'
  document.getElementsByTagName('head')[0].appendChild(script)
}

currency2({
  type: store.state.pageType.id
}).then(res => {
  if (res.status === 1) {
    const typeItem = res.item.find(o => o.id === res.type)
    if (localStorage.getItem('isInitNav') === null) {
      const obj = { id: res.type, title: typeItem.title }
      store.commit('setPageType', obj)
      localStorage.setItem('isInitNav', true)
    }
    store.commit('setUserInfo', res)
    store.commit('setVIPdis', res.VIPdiscount)
    store.commit('setNavItem', res.item)
    if (res.userguid) {
      store.commit('setIsLogin', true)
    } else {
      store.commit('setIsLogin', false)
    }
  }
})

app.mount('#app')
