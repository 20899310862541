import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import 'quasar/dist/quasar.css'
import ch from 'quasar/lang/zh-CN.js'
import en from 'quasar/lang/en-US.js'
import '@quasar/extras/material-icons/material-icons.css'

const lang = localStorage.getItem('language')

Notify.setDefaults({
  position: 'top',
  group: false,
  timeout: 2500
})
export default {
  config: {
    brand: {
      primary: '#000000',
      secondary: '#26A69A',
      accent: '#9C27B0',
      dark: '#000000',
      positive: '#21BA45',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#F2C037'
    }
  },
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  lang: lang === 'en' ? en : ch
}
