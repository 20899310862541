import { defineAsyncComponent, ref, markRaw } from 'vue'

export const components = ref(new Map())
components.value.set(
  '1',
  markRaw(defineAsyncComponent(() => import('./components/showimg1.vue')))
)
components.value.set(
  '2',
  markRaw(defineAsyncComponent(() => import('./components/recommonshop.vue')))
)
components.value.set(
  '3',
  markRaw(defineAsyncComponent(() => import('./components/showimg2.vue')))
)
components.value.set(
  '4',
  markRaw(defineAsyncComponent(() => import('./components/bigimg.vue')))
)
components.value.set(
  '5',
  markRaw(defineAsyncComponent(() => import('./components/goLook.vue')))
)
components.value.set(
  '6',
  markRaw(defineAsyncComponent(() => import('./components/recommonBrand.vue')))
)
export const golink = url => {
  if (url) {
    window.location.href = url
  }
}
