<template>
  <div class="navlist" @mouseleave="onmouseLeave">
    <div class="navtab">
      <div class="listitem" :class="navActive == i || navIndex == i ? 'achover' : ''" @mouseenter="onmouseEnd(i)"
        v-for="(item, i) in navtxt" :key="i">
        <div @click="golink(item)">{{ item.title }}</div>
      </div>
    </div>
    <div class="hoverbox" v-show="showHover" @click="showHover = false">
      <div class="hovermain">
        <div class="hover-title" v-if="navitem.title == '男孩'">
          {{ $t('common.boy') }}
        </div>
        <div class="hoverbox-content">
          <div class="hoveritemimg">
            <div class="imgbox" @click="golink(navitem)">
              <q-img :src="navitem.pictureurl" alt />
            </div>
            <div class="brand-recommend">
              <div v-for="(item, index) in navitem.item" :key="index">
                <div class="tagTitle">
                  {{ $i18n.locale == 'en' ? item.titleen : item.title }}
                </div>
                <div class="childBox">
                  <div class="child" v-for="(jtem, jnx) in item.item" :key="jnx">
                    <a :href="jtem.url">{{
    $i18n.locale == 'en' ? jtem.titleen : jtem.title
  }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle">{{ $t('header_menu.category') }}</div>
            <div class="childBox">
              <div class="child" v-for="(item, index) in navitem.categorys" :key="index">
                <router-link :to="{ path: '/goodslist', query: { params: item.query } }" @click="check(item.query)">{{
    $i18n.locale == 'en' ? item.titleen : item.title
  }}</router-link>
              </div>
              <div class="moretitle" style="margin-top: 20px">
                <div @click="viewAll()">{{ $t('common.view_all') }}</div>
              </div>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle">{{ $t('header_menu.popular') }}</div>
            <div class="childBox" v-if="navitem && navitem.generalattribute">
              <template v-for="(item, index) in navitem.generalattribute[0].item" :key="index">
                <div v-if="index < 10" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle"></div>
            <div class="childBox" v-if="navitem && navitem.generalattribute">
              <template v-for="(item, index) in navitem.generalattribute[0].item" :key="index">
                <div v-if="index < 20 && index >= 10" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle"></div>
            <div class="childBox" v-if="navitem && navitem.generalattribute">
              <template v-for="(item, index) in navitem.generalattribute[0].item" :key="index">
                <div v-if="index < 29 && index >= 20" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
              <div class="moretitle" style="margin-top: 10px">
                {{ $t('header_menu.brand_a_z') }}
              </div>
              <div class="child">
                <router-link :to="{
    path: '/categorybrand',
    query: { typeId: brandAll() },
  }" @click="check2(navitem)">{{ $t('header_menu.view_brans_all') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hovermain" v-if="navitem2">
        <div class="hover-title">{{ $t('common.girl') }}</div>
        <div class="hoverbox-content">
          <div class="hoveritemimg">
            <div class="imgbox" @click="golink(navitem2)">
              <q-img :src="navitem2.pictureurl" alt />
            </div>
            <div class="brand-recommend">
              <div v-for="(item, index) in navitem2.item" :key="index">
                <div class="tagTitle">
                  {{ $t('header_menu.top_brands') }}
                </div>
                <div class="childBox">
                  <div class="child" v-for="(jtem, jnx) in item.item" :key="jnx">
                    <a :href="jtem.url">{{
    $i18n.locale == 'en' ? jtem.titleen : jtem.title
  }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle">{{ $t('header_menu.category') }}</div>
            <div class="childBox">
              <div class="child" v-for="(item, index) in navitem2.categorys" :key="index">
                <router-link :to="{ path: '/goodslist', query: { params: item.query } }" @click="check(item.query)">{{
    $i18n.locale == 'en' ? item.titleen : item.title
  }}</router-link>
              </div>
              <div class="moretitle" style="margin-top: 20px">
                <div @click="viewAll('女孩')">{{ $t('common.view_all') }}</div>
              </div>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle">{{ $t('header_menu.popular') }}</div>
            <div class="childBox" v-if="navitem2 && navitem2.generalattribute">
              <template v-for="(item, index) in navitem2.generalattribute[0].item" :key="index">
                <div v-if="index < 10" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle"></div>
            <div class="childBox" v-if="navitem2 && navitem2.generalattribute">
              <template v-for="(item, index) in navitem.generalattribute[0].item" :key="index">
                <div v-if="index < 20 && index >= 10" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="hoveritem">
            <div class="tagTitle"></div>
            <div class="childBox" v-if="navitem2 && navitem2.generalattribute">
              <template v-for="(item, index) in navitem2.generalattribute[0].item" :key="index">
                <div v-if="index < 29 && index >= 20" class="child">
                  <router-link :to="{ path: '/goodslist', query: { params: item.query } }"
                    @click="check(item.query, '品牌')">{{ item.title }}</router-link>
                </div>
              </template>
              <div class="moretitle" style="margin-top: 10px">
                {{ $t('header_menu.brand_a_z') }}
              </div>
              <div class="child">
                <router-link :to="{
    path: '/categorybrand',
    query: { typeId: brandAll('女孩') },
  }" @click="check2(navitem2)">{{ $t('header_menu.view_brans_all') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import _ from 'lodash'

export default {
  data() {
    return {
      navtxt: [
        { id: '1', title: this.$t('common.men') },
        { id: '2', title: this.$t('common.women') },
        { id: '3', title: this.$t('common.kids') },
        { id: '4', title: this.$t('common.sale') },
        { id: '227', title: this.$t('common.home') },
      ],
      showHover: false,
      navitem: '',
      navitem2: '',
      navIndex: -1,
      navActive: -1,
      timer: null
    }
  },
  computed: {
    navInfo() {
      return store.state.navItem
    },
    brandAll() {
      return function (sex) {
        if (this.navitem) {
          let id = this.navitem.id
          if (sex === '女孩') {
            id = this.navitem2.id
          }
          return id
        }
      }
    },
    pageType() {
      return store.state.pageType
    }
  },
  watch: {
    navInfo: {
      handler(val) {
        if (val) {
          val.forEach(item => {
            item.categorys.forEach(j => {
              const params = _.cloneDeep(store.state.goodsModule.originalParams)
              params.htype = item.id
              params.parentcategoryid = j.guid
              j.query = encodeURI(JSON.stringify(params))
            })
            if (item.generalattribute.length) {
              item.generalattribute[0].item.forEach(j => {
                const params = _.cloneDeep(
                  store.state.goodsModule.originalParams
                )
                params.htype = item.id
                params.generalattributeid = JSON.stringify([{ 品牌: [j.guid] }])
                j.query = encodeURI(JSON.stringify(params))
              })
            }
          })
        }
      }
    },
    pageType: {
      handler(val) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.$route.query.discount === '1') {
            this.navActive = 3
          } else {
            if (val.id === '1') {
              this.navActive = 0
            } else if (val.id === '2') {
              this.navActive = 1
            } else if (val.id === '3' || val.id === '4') {
              this.navActive = 2
            } else if (val.id === '227') {
              this.navActive = 4
            }
          }
        }, 1000)
      },
      immediate: true
    }
  },
  methods: {
    check(params, type) {
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', JSON.parse(decodeURI(params)))
      }
      if (type === '品牌') {
        store.commit('setOnlyBrand', true)
      } else {
        store.commit('setOnlyBrand', false)
      }
    },
    check2(item) {
      if (item.id === store.state.pageType.id) {
        return
      }
      const obj = {
        id: item.id,
        title: item.title
      }
      this.$store.commit('setPageType', obj)
    },
    onmouseEnd(index) {
      this.navIndex = index
      if (index !== 3 && this.navInfo.length) {
        this.navitem = this.navInfo[index]
        if (index === 2) {
          this.navitem2 = this.navInfo[3]
        } else {
          this.navitem2 = ''
        }
        this.showHover = true
      } else {
        this.navIndex = -1
        this.showHover = false
      }
    },
    onmouseLeave() {
      this.navIndex = -1
      this.showHover = false
    },
    // 页面分类跳转
    golink(item) {
      if (item.id === '4') {
        const params = _.cloneDeep(store.state.goodsModule.originalParams)
        params.htype = ''
        const query = JSON.stringify(params)
        if (this.$route.path === '/goodslist') {
          store.commit('updataHandle', params)
        }
        this.$router.push({
          path: '/goodslist',
          query: {
            params: encodeURI(query),
            discount: 1
          }
        })
        this.navActive = 3
      } else {
        const obj = {
          id: item.id,
          title: item.title
        }
        this.$store.commit('setPageType', obj)
        this.$router.push('/')
      }
    },
    viewAll(sex) {
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      if (this.navitem) {
        params.htype = this.navitem.id
      }
      if (this.navitem2 && sex === '女孩') {
        params.htype = this.navitem2.id
      }
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', params)
      }
      const query = JSON.stringify(params)
      this.$router.push({
        path: '/goodslist',
        query: {
          params: encodeURI(query)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navlist {
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  font-size: 14px;
  color: #a8a8a8;
  position: relative;

  .hoverbox {
    width: 100%;
    max-width: 1100px;
    background-color: #f7f7f7;
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    padding: 15px;

    .hovermain {
      .hover-title {
        text-align: left;
        color: #000;
        font-weight: bold;
      }

      &:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #a8a8a8;
      }
    }

    .hoverbox-content {
      display: flex;
    }

    .imgbox {
      width: 250px;
      cursor: pointer;
    }

    .brand-recommend {
      margin-top: 10px;
      text-align: left;

      .tagTitle {
        font-weight: bold;
        color: #000;
      }

      .child {
        font-size: 13px;
        margin-top: 8px;
        color: #666;
        cursor: pointer;
        width: fit-content;
        line-height: 1.2;

        a {
          color: #666;
        }
      }
    }

    .hoveritem {
      text-align: left;
      margin-left: 30px;
      color: #000;
      flex: 1;

      .tagTitle {
        font-weight: bold;
        min-height: 21px;
      }

      .childBox {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .child {
          font-size: 13px;
          margin-top: 8px;
          color: #666;
          cursor: pointer;
          width: fit-content;
          line-height: 1.2;
          text-transform: uppercase;

          a {
            color: #666;
          }

          &:hover {
            color: #000;
          }
        }
      }

      .moretitle {
        width: fit-content;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

.navlist .listitem {
  width: 90px;
  text-align: center;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  border-top: 1px solid #a8a8a8;

  &>div {
    position: relative;
  }

  &:not(:last-child)>div {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background-color: #a8a8a8;
      z-index: 2;
    }
  }

  &.achover {
    background-color: #f7f7f7;

    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 5px;
      background-color: #000;
      position: absolute;
      left: 0;
      top: -5px;
    }
  }
}
</style>
