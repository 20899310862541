<template>
  <div class="container">
    <div class="footerCon pucontainer">
      <div class="email">
        <q-input :maxlength="100" lazy-rules="ondemand" hide-bottom-space dense outlined v-model="email"
          bg-color="white" color="white" :placeholder="$t('footer_menu.your_email')" />
        <div style="height: 10px"></div>
        <q-btn class="full-width" unelevated size="16px" color="white" text-color="black"
          icon-right="iconfont icon-jiantou1" :label="$t('footer_menu.subscribed')" @click="subscribe" />
        <div class="etxt">
          <div><span class="iconfont icon-xin"></span>info@annilux.com</div>
          <div>
            <span class="iconfont icon-shijian"></span>{{ $t("footer_menu.hours24") }}
          </div>
        </div>
      </div>
      <div class="bitem ccon">
        <div>
          <h5>ANNILUX</h5>
          <p @click="thisermsShow = true">{{$t("footer_menu.TermsTitle")}}</p>
          <p @click="exchangesShow = true">{{$t("footer_menu.exchangesTitle")}}</p>
          <p @click="contactShow = true">{{ $t("footer_menu.about_us") }}</p>
          <p @click="policyShow = true">
            {{ $t("footer_menu.service_support") }}
          </p>
        </div>
      </div>
      <div class="bitem lline">
        <h5>{{ $t("footer_menu.classify") }}</h5>
        <p v-for="(item, index) in navtxt" :key="index" @click="golink(item)">
          {{ item.title }}
        </p>
      </div>
      <div class="bitem lline">
        <h5>{{ $t("footer_menu.users") }}</h5>
        <template v-if="islogin">
          <router-link v-for="(item, index) in navarr" :key="index" :to="item.url">{{ item.name }}</router-link>
        </template>
        <template v-else>
          <router-link to="/login">{{ $t("login_mod.login_t") }}</router-link>
          <router-link to="/register">{{ $t("login_mod.reg_t") }}</router-link>
        </template>
      </div>
      <div class="bitem lline">
        <img class="wx-qrcode" src="img/WechatIMG1168.png" alt="">
      </div>
    </div>
    <div class="blackBottom" @click="backTop()">
      &copy; 2015 - 2021 ANNILUX - VAT Number: GB 313 749 012 - 1st Floor, 239
      Kensington High Street, London, United Kingdom, W8 6SN
    </div>
    <q-dialog v-model="contactShow">
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.about_us") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <p>
            We're available Monday to Friday 8am-11pm GMT and Saturday to Sunday
            9am-9pm GMT.
          </p>
          <p>
            Email:
            <span class="blod">info@annilux.com</span>
          </p>
          <p>We'll be in touch soon</p>
          <p>
            We aim to respond within 24 hours, however, during busy periods like
            holidays, sales and special campaigns we may take a little longer
            getting back to you. Want to get in touch with our teams? Scroll
            down for more details.
          </p>
          <p style="text-align: right;">SFASHION LTD</p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="policyShow">
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.service_support") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <p>Our Cookie Policy</p>
          <p>
            This website uses cookies, small files that are sent to your browser
            and stored on your device when you visit a website. Cookies allow
            efficient operation of the website, by optimizing the browsing
            experience. They also provide information to the owner of the
            website for statistical purposes or advertising, mainly to
            personalize the user experience by remembering their preferences.
          </p>
          <p>
            By consenting to the Privacy Policy, the User consents to the use of
            cookies by the website.
          </p>
          <p>
            Disclosure: We are a participant of Luisaviaroma.com's affliate program. We are a participant affiliate of
            Luisaviaroma, Farfetch etc.
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="thisermsShow" full-width>
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.TermsTitle") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="p-list" v-if="$i18n.locale == 'en'">
            <p>ANNILUX's ("ANNILUX" "we" "us") website(s) (our "Site" or "Sites") and related services are made available to you in accordance with the following Terms of Use & Service, and any other rules posted on our Sites (collectively, the "TOS"). Please read the TOS carefully before placing any orders on www.annilux.com. We recommend you should keep a copy of the TOS for future reference.</p>
            <p>ANNILUX  is a trading name of SFashion Limited, a company registered in England and Wales under registration number 3820604. Our registered address is 1st Floor, 239 Kensington High Street, London, W8 6SN, UNITED KINGDOM. Our VAT number is GB 313 749 012.</p>
            <p>In particular, we wish to draw your attention to our policies relating to the terms of purchase within the TOS and our Privacy Policy. We reserve the right to only accept orders from those over 18.</p>
            <p>We may modify the TOS from time to time. Please read the TOS and check back often. If you do not agree to any change to the TOS ，you must immediately stop using the Site. Any changes made after you have placed an order will not affect that order unless we are required to make the change by law.</p>
            <p class="p-sub-title">Account Registration Terms and Conditions</p>
            <p>To use some of the services or features made available to you on this Site ，you will need to register for an account. When you register ， you are required to provide information about yourself that is true, accurate, current, and complete in all respects. Should any of your registration information change, please notify us immediately at the following email address info@annilux.com. We may also change registration requirements from time to time.</p>
            <p>Here are the benefits you can enjoy when you sign up for an ANNILUX account</p>
            <p>Track your orders and review past purchases.<br />Request your return or exchange directly from your account.<br />Build a customized Wish List to create personalized outfits that you can email to friends and family. Plus, you can also print your shopping lists for increased convenience.<br />Add sold out items to your Wish List so you can see if more stock arrives.<br />Select your Favorite Designers so we can tell you when new items have arrived.<br />Save your address and card details so you can shop even quicker next time.<br />Manage your account details, address book and email preferences.<br />To create an account, simply select SIGN IN on the navigation bar,then REGISTER NOW to fill in your details. The account password you create should be unique and kept secure。You must notify ANNILUX immediately of any breach of security or unauthorized use of your account.</p>
            <p>Should you have any issues with your account or need any help in relation to it, then please do not hesitate to contact our Customer Care team. However, please note that we can only communicate with the named account holder in relation to any questions, changes or cancellation of an account.</p>
            <p class="p-sub-title">Forgotten password</p>
            <p>If you have forgotten your password, click here to change it or follow the FORGOTTEN PASSWORD instructions on the SIGN IN page. For security reasons we are unable to send your password via email.</p>
            <p class="p-sub-title">Eligibility to purchase</p>
            <p>In order to make purchases on the Site, you will be required to provide your personal details. In particular, you must provide your real name, phone number, e-mail address and other requested information as indicated. Furthermore, you will be required to provide payment details that you represent and warrant are both valid and correct and you confirm that you are the person referred to in the Billing information provided.</p>
            <p>The Site is available only to individuals and others who meet the ANNILUX terms of eligibility : who have been issued a valid credit/debit card by a bank acceptable to ANNILUX ; whose applications are acceptable to ANNILUX and who have authorized ANNILUX to process a charge or charges on their credit/debit card in the amount of the total purchase price for the merchandise which they purchase. Products purchased by the buyer are for personal or gift use and should not be re-sold, used for commercial purposes or any other commercial benefit. ANNILUX may restrict the quantities of an item being shipped to any one customer or address.</p>
            <p>By making an offer to purchase merchandise, you expressly authorize us to perform credit checks where ANNILUX feels necessary to the extent permitted by applicable laws ; to transmit or obtain information (including any updated information) about you to or from third parties, including but not limited to your credit/debit card number or credit reports (including credit reports for your spouse if you reside in a community property jurisdiction) ; to authenticate your identity, validate your credit/debit card, obtain an initial credit/debit card authorization and authorize individual purchase transactions.</p>
            <p>In addition, you consent to us using the personal information you provide to carry out appropriate anti-fraud checks. The personal information you provide may be disclosed to credit reference agencies or fraud prevention agencies, which may keep a record of this information.</p>
            <p>Please refer to our Privacy Policy for further information about how we use your data.</p>
            <p class="p-sub-title">Orders</p>
            <p>All orders are subject to acceptance and availability,Items in your Shopping Bag are not reserved and may be purchased by other customers.</p>
            <p>All products sold on ANNILUX are in stock and available for dispatch from our distribution center. Occasionally we may need to wait for shipments from our designer suppliers, in this case you need to make an advanced payment to reserve an item. This will ensure that you receive this item in priority once stock has been delivered to ANNILUX. ANNILUX will only take Advance Purchase orders for stock that has been scheduled for delivery by a designer supplier. Your rights regarding Advance Purchase are the same as those for any other purchase at ANNILUX. Alternatively, you may simply choose to register your email address for notification of arrival of the selected merchandise not held in stock.</p>
            <p>Items received into stock will be allocated to qualified Advance Payment orders first. Please be aware that we may be unable to deliver selected Advance Payment merchandise due to production problems or quality issues identified when we receive it. In these circumstances we will notify you by email and refund the Advance Payment to your credit/debit card within thirty days of being advised that merchandise has become unavailable.</p>
            <p>If you have registered your email address for notification of the arrival of a specific product featured on our Site, we will attempt to notify you by email within 48 hours of the product becoming available. Please be aware that certain products that are in particularly high demand will sell out during this period.</p>
            <p>ANNILUX will keep a record of your transactions for a minimum of one year.</p>
            <p class="p-sub-title">Pricing Policy</p>
            <p>Prices shown on the Site are in GBP inclusive of VAT at the applicable rate , unless you have selected an alternative country where VAT is not chargeable, as indicated in the top left-hand corner of the Site.</p>
            <p>All prices and offers remain valid as advertised from time to time. The GBP price of a product displayed on the Site at the time the order is accepted will be honored, except in cases of patent error.</p>
            <p>From time to time, prices are subject to change in response to currency exchange rate changes, markdowns and other commercial factors. The price applicable to your order will be the price current at the time your order is accepted.</p>
            <p>Customers purchasing from a country served as DDP will incur relevant import duty and tax. These costs are included in the final purchase price. If you are a customer whose credit/debit card is not denominated in GBP, the final price will be calculated in accordance with the applicable exchange rate on the day your card issuer processes the transaction.</p>
            <p class="p-sub-title">Acceptance of your order </p>
            <p>Once you have made your choice and placed your order, you will receive an email acknowledging the details of your order. This email is NOT an acceptance of your order, it is a confirmation that we have received it.</p>
            <p>On the confirmation page of your order, we will provide you with an order summary of each product, the price (including all applicable fees and taxes), and the cost of shipping.</p>
            <p>Unless you cancel your order, acceptance of your order and completion of the contract between you and ANNILUX will be completed when we email you to confirm the goods have been dispatched. English is the language used for finalising the contract. Neither our third-party payment processor nor our nominated courier has the authority to accept an order on behalf of ANNILUX.</p>
            <p>We reserve the right not to accept your order in the event, for example, that we are unable to obtain authorization for payment; that shipping restrictions apply to a particular item; that the item ordered is out of stock or does not satisfy our quality control standards and is withdrawn or that you do not meet the eligibility criteria set out within the TOS.</p>
            <p>We may refuse to process a transaction for any reason or refuse service to anyone at any time when the transaction or service does not comply with our business policy, or in the event where there are reports or suspicion of fraudulent or illegal activity. In these events, we will not be liable for refusing to process a transaction or unwinding or suspending any transaction after processing has begun. To the extent permitted by the applicable law, we will not be liable to you or any third party by reason of our withdrawing any merchandise from the Site whether if that merchandise has been sold, removing, screening or editing any materials or content on the Site. We reserve the right not to accept orders received from users who are acting for commercial and professional purposes.</p>
            <p>Whilst every effort is made to make sure details on our Site are accurate, we may from time to time discover an error in the pricing of products. If we discover a patent error in the pricing of a product in your order, we will let you know as soon as possible. We shall be under no obligation to accept or fulfil an order for a product that was advertised at an incorrect price, provided that the error was recognizable, and reserve the right to cancel such an order that has been accepted or is in transit. If you order a product that is patently priced incorrectly for any reason, we will inform you by email/telephone that we have not accepted your order and/or that the relevant part of your order has been cancelled. If you have already paid for the goods, we shall refund the full amount as soon as we are able. In the event that products are recalled in transit, we will process your refund once the products have been returned to us.</p>
            <p class="p-sub-title">Insurance and delivery</p>
            <p>ANNILUX insures each purchase during the time it is in transit until it is delivered to your specified delivery address. We require a signature for any goods delivered, at which point responsibility for your purchased goods passes to you. If you have specified a recipient who is not you for delivery purposes (for example as a gift) then you accept that evidence of a signature by them (or at that delivery address) is evidence of delivery and fulfillment by ANNILUX, and transfer of responsibility in the same way. The goods are your responsibility from the time we deliver them to the address you gave us.</p>
            <p>Please note that we aim to dispatch all orders within 24 hours, or 48 hours during sale periods. Delivery times appearing on the Site are estimates and for guidance only. It commences from the date of dispatch. In any event, we will deliver the goods to you within 30 days when we accept your order. ANNILUX is not responsible for any delays caused by destination customs clearance processes.</p>
            <p class="p-sub-title">Returns and exchanges</p>
            <p>Please click here for details of our Exchanges & Returns Policy, which is part of these Terms and Conditions.</p>
            <p class="p-sub-title">No commercial use</p>
            <p>This Site is for your personal non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, commercially exploit, create derivative works from, transfer, data mine or sell any content, software, products, or services contained within this Site. You may not use this Site, or any of its content for  any commercial purposes, including any advertising or advertising revenue generation activity on your own Site.</p>
          </div>
          <div class="p-list" v-else>
            <p>ANNILUX（以下称"ANNILUX"）、"我们网站"、"我们的网站"、"本网站"和相关服务系根据以下使用条款和服务条款，以及我们网站上发布的任何其他规则（统称"服务条款"）向您提供。在www.annilux.com网站下任何订单前，请仔细阅读服务条款。我们建议您保留一份 服务条款以便日后参考。</p>
            <p>ANNILUX是 SFashion Limited的商号，后者是一家在英格兰和威尔士注册的公司，注册号为313 749 012。我们的注册地址为1st Floor, 239 Kensington HighStreet, London, United Kingdom, W8 6SN。我们的增值税号码为GB 313 749 012。</p>
            <p>我们特别想提醒您注意服务条款中有关购买条款的政策，以及我们的隐私政策。如果您未满 16 周岁，在注册使用本网站或本网站的任何服务之前，必须让您的父母或监护人了解ANNILUX的隐私政策。不过，我们有权仅接受年满 18 周岁顾客的订单。</p>
            <p>我们可能会不时修改服务条款。请阅读本服务条款并经常回来查看。如果您不同意服务条款 的任何修改，则必须立即停止使用本网站。在您下订单后本网站所做的任何更改将不会影响该订单，除非我们是按照法律要求进行的更改。</p>
            <p class="p-sub-title">注册和账户条款与条件</p>
            <p>要使用本网站向您提供的某些服务或功能，您将需要注册一个账户。注册时，您必须提供关于您本人的信息，这些信息必须完全真实、准确、最新且完整。如果您的任何注册信息发生变化，请立即通过以下电邮地址通知我们：info@annilux.com。我们也可能不时改变注册要求。</p>
            <p>以下是您注册 ANNILUX 账户后可以享受的礼遇：<br />追踪您的订单并查看过去的购买记录，通过账户直接发送退换货请求，创建一个私人的愿望清单，通过邮件将心动造型发送给亲友；同时还可以将愿望清单打印出来，将售罄单品加入愿望清单，待单品重新上线后可以第一时间购买，锁定挚爱设计师品牌，第一时间获得新品上线信息，保存地址和银行卡信息，为下一次购物节省时间，管理账户信息、地址簿和邮件偏好设置。</p>
            <p>创建新账户时，只需点击“登陆”，然后点击“立即注册”输入个人信息。您所创建的账户密码必须是唯一并安全的，如发现任何违反安全或未经授权使用您帐户的情况，请立即通知ANNILUX</p>
            <p>如果您的帐户有任何问题或需要任何帮助，请随时联系我们的客户服务团队。但是，请注意，我们只能就任何问题、更改或取消帐户与指定帐户持有人进行沟通。</p>
            <p class="p-sub-title">忘记密码</p>
            <p>如您忘记密码，请点击这里进行修改，或遵循登录页“忘记密码”的指示操作。由于安全原因，我们无法通过邮件发送您的密码。</p>
            <p class="p-sub-title">购买资格Eligibility to purchase</p>
            <p>要在本网站上购物，您必须提供个人详细信息。特别是，您必须提供真实姓名、电话号码、电邮地址以及根据指示要求的其他信息。此外，您还必须提供详细付款信息，声明且保证其正确有效，并且确认您是所提供账单信息中所指的人。</p>
            <p>本网站仅可供以下个人和非个人使用：符合ANNILUX资格条件，拥有 ANNILUX接受的银行所发放的有效信用卡/借记卡，ANNILUX接受其申请，已授权ANNILUX在其信用卡/借记卡上扣除等于所购买商品总价格的金额。买者购买的产品无论是个人使用还是馈赠礼品，不应进行转售，不应用于商业用途或谋取其他任何商业效益。另外，ANNILUX保留对配送至任何顾客或地址的订单限制单件产品购买数量的权利。</p>
            <p>在本网站下订单即表示，您明确授权我们执行信用检查，在ANNILUX认为必要时仅为了验证您的身份、验证您的信用卡/借记卡、获取初始信用卡/借记卡授权和授权个体购买交易，向第三方发送或从第三方获取您的信息（包括任何更新的信息），包括但不限于您的信用卡/借记卡号或信用报告（包括您配偶的信用报告，如果您居住在共同财产制司法辖区内）。</p>
            <p>此外，您同意我们使用您提供的个人信息进行适当的防欺诈检查。您提供的个人信息可能会被披露给信用咨询机构或防欺诈机构，它们可能会保留这些信息的记录。</p>
            <p>有关我们如何使用您的数据的进一步信息，请参考我们的隐私政策。</p>
            <p class="p-sub-title">订单</p>
            <p>所有订单是否生效都取决于该订单的接受情况和供货情况，您已放入购物篮里的商品不会被预留，仍可以被其他顾客购买。</p>
            <p>ANNILUX出售的产品都有现货，并且能够从我们的配送中心发货。但是，在有些情况下，我们可能要等待我们的品牌供应商发货。因此，在您能够进行预购的交易中，有时可能需要对特定商品进行预付款。这将确保一旦品牌货品运送到 ANNILUX后，您优先收到该商品。ANNILUX将仅接受品牌供应商已计划配送的货品的预购订单。有关预售商品，您将享受与 ANNILUX任何其他商品相同的权利。或者，您可以选择登记您的电邮地址，以便在所选缺货商品到货后收到通知。</p>
            <p>到货的商品将会预先分配给预付款订单，因此相对于等待名单中的顾客或通过网站进行即刻发货订购的顾客，进行预付款的顾客将优先收到商品。请注意，如果订单中的商品到货后，我们发现了生产问题或质量问题，则可能无法针对该特定预付款商品进行发货。在这些情况下，我们将通过电邮通知您，并在得知商品无法发货的30天内将预付款退还到您的信用卡/借记卡中。</p>
            <p>如果您已经针对特定产品注册了电邮地址，以便收到我们提供的产品到货通知，我们将尽力在本网站提供该产品后的48小时内通过电邮通知您。请注意，需求量特别高的产品有时会在此期间内售罄。</p>
            <p>ANNILUX会将您的交易记录保留至少一年。</p>
            <p class="p-sub-title">定价政策</p>
            <p>本网站中显示的价格以英镑或欧元为单位，含适用税率的增值税，除非您选择了不收增值税的其他国家/地区（如本网站左上角所示）。</p>
            <p>不时公布的所有价格和报价会保持有效。我们将使用接受订单时本网站中显示产品的英镑或欧元价格，除非存在明显错误。</p>
            <p>商品价格会根据货币汇率、打折促销和其它商业元素而相应变动。您订单中所显示的价格将以订单被接受时的现行价格为准。</p>
            <p>从作为DDP的国家/地区购买的客户将产生相关的进口关税和税款。这些费用已包含在最终购买价格中。如果您是信用卡/借记卡不以英镑计价的客户，则最终价格将根据您的发卡机构处理交易当天的适用汇率计算。</p>
            <p class="p-sub-title">订单接受</p>
            <p>您选择好商品并下订单后，将收到一封电邮，确认您订单的详细信息。这封电邮并不表示我们接受了您的订单，而只是确认我们收到了该订单。</p>
            <p>在您订单的确认页面上，我们将为您提供每种产品的订单摘要、价格（包括所有适用的费用和税费）以及运费。</p>
            <p>除非您取消了订单，否则只有在我们向您发送电邮确认商品已发货后，才表示我们接受了您的订单，并且您与ANNILUX之间的合同成立。此时，销售合同成立，成立地为英格兰伦敦，合同语言为英语。</p>
            <p>在特定情况下，我们有权不接受您的订单，例如：我们无法获得付款授权，特定商品受到运输限制，订购的商品无现货或不符合我们的质量检验标准而撤销，或者您不符合我们服务条款中规定的资格条件。</p>
            <p>我们还可以出于任何原因拒绝处理交易，并因此拒绝接受交易，或随时自行决定拒绝向任何人提供服务。我们不因从本网站中撤销任何商品（无论该商品是否出售），删除、过滤或编辑本网站中的任何资料或内容，拒绝处理交易或者开始处理后撤销处理或暂停任何交易，而对您或任何第三方承担任何责任。</p>
            <p>我们会尽一切努力来保证网站信息准确无误，但可能仍然会出现商品价格出错的情况。如果我们发现您订单中某个商品价格有误，我们会尽快通知您。如果您的订单中出现某一商品在广告中标价失误的情况，我们没有义务去接受或完成这笔订单，并保留取消这笔订单（无论是否已被接受或正在配送途中）的权利。如果您的订单中包含由于任何原因而造成标价有误的商品，我们将会通过电子邮件或电话方式联络您，并告知您我们没有接受您的订单，以及/或者您订单中的相关部分已被取消。如果您已经为相关商品付款，我们将以全额方式尽快为您退还。如果商品在配送途中被找回，我们将会在收到商品后为您办理退款事宜。</p>
            <p class="p-sub-title">保险和配送</p>
            <p>NETAPORTER 颇特女士对每笔订单均有投保，保险在ANNILUX发货之后到货品送抵您的收货地址期间有效。我们要求对交付的所有商品签收，至此，对您所购买商品的责任将转移到您身上。如果由于交付商品的原因（例如作为礼物），您指定了自己以外的其他收货人，则您承认他们的签名证据（或者该送货地址）为 ANNILUX交付商品和履约的证据，责任以相同方式转移。</p>
            <p>请注意，我们的目标是在 24 小时内或销售期间 48 小时内发货。网站上显示的交货时间是估计的，仅供参考，自发货之日起计算。无论如何，我们将在接受您的订单之日起 30 天内将货物交付给您。ANNILUX对目的地清关流程造成的任何延误概不负责。</p>
            <p class="p-sub-title">退货和换货</p>
            <p>请点击此处了解我们的换货和退货政策的详细信息，该政策是这些条款和条件的一部分。</p>
            <p class="p-sub-title">无商业用途</p>
            <p>本网站仅供您个人非商业用途。您不得修改、复制、分发、传输、展示、执行、复制、发布、许可、商业利用、创建衍生作品、转让、数据挖掘或出售本网站中包含的任何内容、软件、产品或服务。您不得将本网站或其任何内容用于任何商业目的，包括您自己网站上的任何广告或广告创收活动。</p>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="exchangesShow" full-width>
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.exchangesTitle") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="p-list" v-if="$i18n.locale == 'en'">
            <p>Exchanging or returning an item couldn’t be easier. Try your purchase on in the comfort of your own home and let us know straightaway if you’d like a different size or a refund and we’ll collect from your home, work or an alternative address.</p>
            <p>You have 14 days from receiving your order to:</p>
            <p>Create your exchange or return here. If you are a registered user, simply log into your ANNILUX account.</p>
            <p>Please ensure that the goods you wish to exchange or return are in new, unused condition with all the original label attached and in the original packaging. <br />We reserve the right to refuse your exchange or return request if all of the conditions above are not met.</p>
            <p>Take your return to your nearest drop off point or book your complimentary collection with DHL Express.</p>
            <p>Send your purchase back to us.</p>
            <p>There’s no cost to exchange your purchase, however if you’re based outside the EU, taxes and duties are applicable. If you wish to try another style, simply return it for a store credit refund and purchase the new item separately.</p>
            <p class="p-sub-title">Returning your purchase</p>
            <p>As soon as you've decided you'd like to exchange or return an item, you have 14 days from the date you received your order. Please follow the below steps: </p>
            <p>Create your exchange or return here. If you are a registered user, simply log into your ANNILUX account. In My Orders section, select the order number containing the item(s) you would like to return.</p>
            <p>Click on the "CREATE EXCHANGE/RETURN" button and choose the reason for return. If you’d like an exchange, select your preferred size. </p>
            <p>Check the box to agree with our Return Policy. If you have misplaced your return label, you can request a replacement in the 'My Orders' section in your ANNILUX account. We will email you the return label in 48 hours to the email address used to place your order.</p>
            <p>Sign the returns proforma invoice included in the box with your order. If you no longer have this, you can find a replacement PDF copy attached to your Dispatch Email. Seal your box before handing it to the courier.</p>
            <p class="p-sub-title">Return your purchase:</p>
            <p>a) Return for free at your nearest DHL Express service point. Please find your nearest drop-off point here<br />b) Book a free returns collection:</p>
            <p class="p-sub-title">Additional Information</p>
            <p>To ensure your return is covered by our free returns policy and reaches us promptly, items must be returned from the same country to which they were delivered. Orders sent back from a different destination may incur additional charges or be delayed by customs.</p>
            <p>You can choose to receive your refund either as store credit to your ANNILUX  account or back to the original payment method.</p>
            <p>Please note that refunds can take up to 10 working days to show on your account due to varying processing times between payment providers. </p>
            <p>ANNILUX will not refund you the custom duties and sales taxes for orders outside of the EU Countries. However you could contact your local customs or an agent to assist you in this matter.</p>
            <p>As the destination of your order is within the EU or the United Kingdom, all sales taxes will be refunded. </p>
            <p class="p-sub-title">Repeated returns not in compliance with the Return Policy</p>
            <p>We offer a flexible return policy to make your online shopping experience even easier. We do monitor the number of returns made by customers to check whether the purchase of products is pursued for consumer purposes and is not, on the contrary, pursued for commercial, entrepreneurial or professional purposes, and/or is otherwise related to fraudulent intent. Frequent or repeated returns and in breach of our terms and conditions will be flagged. In this case, at our discretion, we may proceed to close your ANNILUX account or refuse future orders.</p>
            <p class="p-sub-title">Our Return Policy</p>
            <p>All products must be returned in a new , unused and perfect condition, with all protective materials in place and the ANNILUX and designer tags attached to them (if applicable). This includes all packaging, accessories and authenticity cards/dust bags. We reserve the right not to accept any return if the product shows signs of wear or has been used or altered from its original condition in any way or, as an alternative, may reduce the amount of any applicable refund or exchange accordingly.</p>
            <p>For example, we may make a reduction in the following circumstances:</p>
            <p>the product(s) have been used, worn, damaged or washed;</p>
            <p>the designer and ANNILUX tag are not attached to the product(s) or have not been returned. Please note that the ANNILUX and designer tags constitute an integral part of each product; and/or</p>
            <p>you have not returned any packaging which is considered an integral part of a product (such as a dust bag).</p>
            <p>You will be informed if we propose to reduce your refund for this reason. In this instance, you may (as an alternative to us providing you with a reduced refund) choose to have the products sent back to you at your own expense.</p>
          </div>
          <div class="p-list" v-else>
            <p>我们的退换流程非常简单。如果您在舒适的家中试穿（用）单品后希望换不同尺码或申请退款，只需马上通知我们即可。我们将直接从您的家中、工作地点或其他指定地址上门取回要退换的商品。<br />您在收到订单后的14天内可以：<br />在这里创建您的换货或退货，或者，如果您是注册用户，请登录您的 ANNILUX账户<br/>请确保您要退换的商品是全新并没有被使用过的，且仍然挂有全部 ANNILUX及品牌吊牌。对于不符合要求的商品，我们将不予接受，并会原样寄回给顾客。将您的退货地点带到最近的投递点或通过 DHL Express 预订免费取件，将您的购买寄回给我们，换货不收取任何费用，但如果您位于欧盟以外，则需缴纳税费和关税。如果您想尝试其他款式，只需将其退回即可获得商店积分退款并单独购买新商品</p>
            <p class="p-sub-title">退货</p>
            <p>一旦您选择换货或退货，请告知我们您的决定。从收到订单之日起，您有14天的时间来按照以下步骤退还您的商品：<br />登录您的帐户，在“我的订单”下，选择包含所需退还商品的订单号。<br />单击“创建换货/退货”按钮并选择退货原因。如果您想换货，请选择您喜欢的尺码。<br />选中该框以同意我们的退货政策。如果您放错了退货标签，可以在 ANNILUX账户的“我的订单”部分申请更换。然后，您将在接下来的 48 小时内收到一封电子邮件，并在用于下订单的电子邮件地址上贴上标签。在退货单上签名并将其与商品一并置于纸箱中。如果找不到退货单，您可以在配送确认邮件中找到 PDF 版本。请将盒子密封好，再交给快递员。</p>
            <p class="p-sub-title">退货方式：</p>
            <p>a）DHL 网点免费退货<br />b）预约免费上门取件</p>
            <p class="p-sub-title">附加信息</p>
            <p>为了保证您可以享受到我们的免费退货政策并确保所退还的商品尽快送达，商品必须从配送目的地所在的国家或地区退还。从不同目的地退回的商品有可能会产生额外费用或被海关延误。您可以选择退款以网上购物礼金的形式退还至您的 ANNILUX 账户，也可以按原始付款方式退款。请注意，鉴于不同支付方式所需的处理时间不同，退款金额将在最多10个工作日内显示在您的账户。对于欧盟之外的配送目的地，ANNILUX不会退还关税和销售税。但是，您可以联系当地海关或雇佣一位海关经纪人索回这些费用。由于您的订单将发送到欧盟或英国境内的目的地，因此将退还所有销售税。</p>
            <p class="p-sub-title">重复退货不符合退货政策</p>
            <p>我们提供灵活的退换货政策，以确保您享受到轻松便捷的线上购物体验。但我们同时也会记录顾客的退换货次数，用于检查商品的购买是以满足顾客需求为目的，还是以商业、企业、专业和/或欺诈为目的。频繁或重复的退换货，以及违反我们的条款与条件，都会被记录下来。出于上述原因，我们可能会关闭您的NETAPORTER账户或拒绝日后的下单。</p>
            <p class="p-sub-title">政策声明</p>
            <p>所有商品必须以全新且未使用过的状态退回，完好无损，所有保护材料均已到位，并附有 ANNILUX标签（如适用）。这包括所有包装、配件和真品卡/防尘袋。如果产品出现磨损迹象或以任何方式使用或改变其原始状态，我们保留不接受任何退货的权利，或者作为替代方案，可能会相应地减少任何适用的退款或换货金额。<br />例如，在以下情况下，我们可能会减少退款：<br />产品已被使用、磨损、损坏或洗涤;<br />ANNILUX标签未附在产品上或未退回。请注意，ANNILUX和设计师标签是每件产品不可分割的一部分;和/或
您没有退回任何被视为产品不可分割部件的包装（例如集尘袋）<br />如果我们因此而建议减少您的退款，您将收到通知。在这种情况下，您可以（作为我们为您提供减少退款的替代方案）选择自费将产品寄回给您。</p>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-scroll-observer @scroll="onScroll" />
    <div class="backtop" :class="top > 1000 ? 'show' : 'hide'">
      <div class="cont" @click="backTop()">
        <span class="iconfont">&#xe609;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mailbox } from '@/api/common'
import { emailRule } from '@/common/formRules'
import store from '@/store'
import _ from 'lodash'

export default {
  data() {
    return {
      navarr: _.cloneDeep(store.state.navList),
      email: '',
      contactShow: false,
      policyShow: false,
      thisermsShow: false,
      exchangesShow: false,
      isBreak: false,
      speed: 0,
      top: 0,
      navtxt: [
        { id: '1', title: this.$t('common.men') },
        { id: '2', title: this.$t('common.women') },
        { id: '3', title: this.$t('common.boy') },
        { id: '4', title: this.$t('common.girl') },
        { id: '227', title: this.$t('common.home') },
      ]
    }
  },
  computed: {
    islogin() {
      return this.$store.state.isLogin
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  watch: {
    userInfo: {
      handler() {
        this.navarr = _.cloneDeep(store.state.navList)
        if (this.userInfo.usertype === '1') {
          this.navarr.push({
            name: this.$t('navList.sale'),
            url: '/my/salesRecord'
          })
          this.navarr.push({
            name: this.$t('navList.invite'),
            url: '/my/inviteRecord'
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    async subscribe() {
      if (this.email === '') {
        this.$q.notify(this.$t('footer_menu.enter_email'))
        return
      } else if (!emailRule(this.email)) {
        this.$q.notify(this.$t('footer_menu.email_err'))
        return
      }
      const res = await mailbox({
        mailbox: this.email
      })
      if (res.status === 1) {
        this.$q.notify(this.$t('footer_menu.subscription_succeeded'))
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    },
    golink(item) {
      window.scrollTo(0, 0)
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      params.htype = item.id
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', params)
      } else {
        const query = JSON.stringify(params)
        this.$router.push({
          path: '/goodslist',
          query: {
            params: encodeURI(query)
          }
        })
      }
      const obj = {
        id: item.id,
        title: item.title
      }
      setTimeout(() => {
        this.$store.commit('setPageType', obj)
      }, 100)
    },
    onScroll(e) {
      this.speed = Math.floor(-e.position.top / 10)
      if (this.top < e.position.top) {
        this.isBreak = true
      } else {
        this.isBreak = false
      }
      this.top = e.position.top
    },
    backTop() {
      let timer = null
      clearInterval(timer)
      this.isBreak = false
      timer = setInterval(() => {
        window.scrollTo(0, this.top + this.speed)
        if (this.top === 0 || this.isBreak) {
          clearInterval(timer)
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
  color: #fff;
  background-color: #000;
}

.footerCon {
  max-width: 1500px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .email {
    width: 450px;
    margin-left: 10px;

    .etxt {
      margin-top: 10px;
      height: 35px;
      padding: 5px 0;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      display: flex;

      div {
        display: inline-block;
        width: 50%;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;

        span {
          margin-right: 5px;
        }
      }

      &>div:nth-of-type(1) {
        border-right: 1px solid #fff;
      }
    }

    :deep(.q-field__native, .q-field__input) {
      font-size: 16px;
      text-align: center;
    }

    :deep(.q-icon) {
      position: absolute;
      font-size: 16px;
      right: 10px;
    }
  }

  .bitem {
    flex: 2;
    text-align: left;
    padding-left: 10px;
    text-transform: uppercase;

    div {
      display: inline-block;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      text-align: left;
    }

    p {
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 0;
      cursor: pointer;
      width: fit-content;

      &:hover {
        text-decoration: underline;
      }
    }

    a {
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 0;
      cursor: pointer;
      width: fit-content;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .lline {
    flex: 1;
  }

  .ccon {
    text-align: center;
  }

  .wx-qrcode {
    width: 150px;
    height: 150px;
    margin-right: 15px;
  }
}

.blackBottom {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.backtop {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 99;
  transition: opacity 0.4s;
  cursor: pointer;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  .cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 2px;
    color: #fff;

    .iconfont {
      font-size: 12px;
      transform: rotate(-90deg);
    }
  }
}

.p-list {
  display: flex;
  flex-direction: column;

  .p-sub-title {
    font-weight: bold;
    margin-bottom: 0;
  }
}
</style>
