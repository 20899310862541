import Index from '../views/Index/index.vue'
import Login from '../views/Login/index.vue'
import my from './modules/my'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    // 登录
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      isnav: false
    }
  },
  {
    // 注册
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Register/index.vue'),
    meta: {
      isnav: false
    }
  },
  {
    // 商品详情
    path: '/Goods',
    name: 'Goods',
    component: () => import('../views/Goods/Goods.vue')
  },
  {
    // 商品列表和筛选
    path: '/Goodslist',
    name: 'Goodslist',
    component: () => import('../views/Goods/goodslist/GoodsMain.vue')
  },
  {
    // 品牌筛选
    path: '/categorybrand',
    name: 'Brandfilter',
    component: () => import('../views/Goods/Brandfilter.vue')
  },
  {
    // 确认订单
    path: '/chichek',
    name: 'chichek',
    component: () => import('../views/ShopCart/Chichek.vue'),
    meta: {
      islogin: true
    }
  },
  {
    // 确认订单
    path: '/chichek2',
    name: 'chichek2',
    component: () => import('../views/ShopCart/Chichek2.vue')
  },
  {
    path: '/cart',
    component: () => import('@/views/ShopCart/Cart.vue')
  },
  {
    path: '/collection',
    component: () => import('@/views/My/Collection.vue')
  },
  {
    // 支付
    path: '/payment',
    name: 'payment',
    component: () => import('../views/ShopCart/Payment.vue'),
    meta: {
      islogin: true
    }
  },
  {
    // 支付成功
    path: '/paymentInfo',
    name: 'paymentInfo',
    component: () => import('../views/ShopCart/PaymentInfo.vue'),
    meta: {
      islogin: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      islogin: true
    }
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('../views/404.vue')
  },
  ...my
]

export default {
  routes
}
