import { createStore } from 'vuex'
import i18n from '../language'

import goodsModule from './goodslist'
import global from './global'

const t = i18n.global.t

export default createStore({
  state: {
    userInfo: '',
    navItem: '',
    isLogin: false,
    pageType: JSON.parse(localStorage.getItem('pageType')),
    saleGuid: '',
    chichekInfo: '',
    navList: [{ name: t('navList.account'), url: '/my/info' }, { name: t('navList.orders'), url: '/my/order' }, { name: t('navList.return'), url: '/my/returnGoodsList' }, { name: t('navList.vip'), url: '/my/balance' }, { name: t('navList.address'), url: '/my/address' }],
    VIPdis: [],
    memberInfo: {} || ''
  },
  mutations: {
    setMemberInfo(state, value) {
      state.memberInfo = value
    },
    setUserInfo (state, value) {
      if (value === '') return
      state.userInfo = value
      localStorage.setItem('userInfo', JSON.stringify(value))
    },
    setNavItem (state, value) {
      if (value === '') return
      state.navItem = value
      localStorage.setItem(`navItem${global.state.channel}`, JSON.stringify(value))
    },
    setIsLogin (state, value) {
      state.isLogin = value
      localStorage.setItem('isLogin', value)
    },
    setPageType (state, value) {
      state.pageType = value
      localStorage.setItem('pageType', JSON.stringify(value))
    },
    setSaleGuid (state, value) {
      state.saleGuid = value
    },
    setChichekInfo (state, value) {
      state.chichekInfo = value
    },
    setVIPdis (state, value) {
      state.VIPdis = value
    }
  },
  modules: {
    global,
    goodsModule
  }
})
