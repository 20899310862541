import axios from '@/utils/request'
import qs from 'qs'

/* 获取商品列表 */
export const goodslist = (param) => {
  return axios.post(
    '/common/listcommodity.ashx',
    qs.stringify({
      ...param
    })
  )
}
/* 获取商品详情 */
export const getgoods = (params) => {
  return axios.post(
    '/common/commoditydetails.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 添加收藏 */
export const addwish = (guid) => {
  return axios.post(
    '/common/wish.ashx',
    qs.stringify({
      type: 1,
      ...guid
    })
  )
}

/* 购物车api */
export const cartApi = (info) => {
  return axios.post(
    '/common/shipping.ashx',
    qs.stringify({
      ...info
    })
  )
}

/* 获取下单优惠券 */
export const getOrderDisApi = (param) => {
  return axios.post(
    '/common/VIPdiscount.ashx',
    qs.stringify({
      action: 'use_VIPdiscount',
      ...param
    })
  )
}

/* 下单api */
export const payApi = (params) => {
  return axios.post(
    '/common/order.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 获取订单支付状态 */
export const payStatus = (param) => {
  return axios.post(
    '/common/pay.ashx',
    qs.stringify({
      ...param
    })
  )
}

/* 生成二维码 */
export const wxcode = (params) => {
  return axios.post(
    '/common/wxacode.ashx',
    qs.stringify({
      ...params
    })
  )
}

// 获取订单系统
export const getOrderDetailApi = (params) => {
  return axios.post(
    '/admin/commons/orders.ashx',
    qs.stringify({
      ...params
    })
  )
}
