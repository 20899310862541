import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
  EffectFade,
  Virtual
} from 'swiper'
import 'swiper/swiper.scss'
import '@/assets/css/swiper/navigation.scss'
import '@/assets/css/swiper/pagination.scss'
import '@/assets/css/swiper/scrollbar.scss'

export const swiperConfig = () => {
  return {
    Swiper,
    SwiperSlide,
    Navigation,
    SwiperCore,
    Pagination,
    Scrollbar,
    Autoplay,
    A11y,
    EffectFade,
    Virtual
  }
}
