<template>
  <div class="container">
    <!-- 我的栏目 -->
    <div class="mynav">
      <div
        :class="['navitem', nowIndex == i ? 'active' : '']"
        v-for="(item, i) in navarr"
        :key="i"
        @click="topath(item.url, i)"
      >{{ item.name }}</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data () {
    return {
      navarr: JSON.parse(JSON.stringify(store.state.navList)),
      nowIndex: 0
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    topath (paths, i) {
      this.nowIndex = i
      if (this.$route.path === paths) return
      if (paths !== '') {
        this.$router.push({ path: paths })
      }
    }
  },
  watch: {
    $route (to) {
      if (this.$route.meta.navid) {
        this.nowIndex = this.$route.meta.navid
      } else if (to.matched.length > 2 && !to.matched[2].meta.navid) {
        this.nowIndex = to.matched[1].meta.navid
      } else {
        if (this.$route.meta.navid === 0) {
          this.nowIndex = 0
        } else {
          this.nowIndex = -1
        }
      }
    }
  },
  created () {
    if (this.userInfo.usertype === '1') {
      this.navarr.push({
        name: this.$t('navList.sale'),
        url: '/my/salesRecord'
      })
      this.navarr.push({
        name: this.$t('navList.invite'),
        url: '/my/inviteRecord'
      })
    }
  },
  mounted () {
    if (this.$route.meta.navid) {
      this.nowIndex = this.$route.meta.navid
    }
  }
}
</script>

<style lang="scss" scoped>
.mynav {
  width: 100%;
  height: 40px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  .navitem {
    min-width: 108px;
    font-size: 14px;
    color: #000;
    padding: 0 10px;
    cursor: pointer;
    text-align: center;
  }
  .navitem.active {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
