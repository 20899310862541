import { currency2 } from '@/api/common'
import store from '@/store'

// 更新头部购物车数量和收藏数量
export const upDatedHead = () => {
  currency2({
    type: store.state.pageType.id
  }).then((res) => {
    store.commit('setUserInfo', res)
    if (res.userguid !== '') {
      store.commit('setIsLogin', true)
    } else {
      store.commit('setIsLogin', false)
    }
  })
}
