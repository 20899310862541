// 封装axios的请求，返回重新封装的数据格式
import axios from 'axios'
import errorHandle from './errorHandle'
import { upDatedHead } from '@/common/upDatedHead'
import router from '@/router'
import Dialog from 'quasar/src/plugins/Dialog.js';
import i18n from '../language'
import store from '../store'

const CancelToken = axios.CancelToken

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.pending = {}
  }

  // 获取axios配置
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 30000
    }
    return config
  }

  // 去除重复请求
  removePending(key, isRequest = false) {
    if (this.pending[key] && isRequest) {
      this.pending[key]('取消重复请求')
    }
    delete this.pending[key]
  }

  // 设定拦截器
  interceptors(instance) {
    instance.interceptors.request.use(
      (config) => {
        store.commit('startLoading')
        const key = config.url + '&' + config.method
        // this.removePending(key, true)
        config.cancelToken = new CancelToken((c) => {
          this.pending[key] = c
        })
        return config
      },
      (err) => {
        errorHandle(err)
        return Promise.reject(err)
      }
    )
    // 响应请求的拦截器
    instance.interceptors.response.use(
      (res) => {
        store.commit('finishLoading')
        // 去除重复请求
        // const key = res.config.url + '&' + res.config.method
        // this.removePending(key)

        // 登录过期
        if (res.data.status === -1008) {
          upDatedHead()
          const route = router.currentRoute
          router.push({
            path: '/login',
            query: {
              redirect: route.value.fullPath
            }
          })
          setTimeout(() => {
            Dialog.create({
              title: i18n.global.locale === 'en' ? 'TIP' : '提示',
              message: i18n.global.locale === 'en' ? res.data.contenten : res.data.content
            })
          }, 100)
        }
        if (res.data.status !== 1) {
          errorHandle(res)
        }
        // 更新头部购物车，心愿数量
        if (res.data.status === 1 || res.data.status === 2) {
          if (
            res.config.url === '/common/shipping.ashx' ||
            res.config.url === '/common/wish.ashx'
          ) {
            upDatedHead()
          }
        }
        if (res.status === 200) {
          return Promise.resolve(res.data)
        } else {
          return Promise.reject(res)
        }
      },
      (err) => {
        store.commit('finishLoading')
        errorHandle(err)
        return Promise.reject(err)
      }
    )
  }

  // 创建实例
  request(options) {
    const instance = axios.create()
    const newOptions = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)
    return instance(newOptions)
  }

  get(url, config) {
    const options = Object.assign(
      {
        method: 'get',
        url: url
      },
      config
    )
    return this.request(options)
  }

  post(url, data) {
    return this.request({
      method: 'post',
      url: url,
      data: data
    })
  }
}

export default HttpRequest
