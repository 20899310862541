// 语言
import { createI18n } from 'vue-i18n'
let lang = 'ch'
if (localStorage.getItem('language') === 'en') {
  lang = 'en'
} else {
  lang = 'ch'
}
const i18n = createI18n({
  locale: lang, // 默认显示的语言
  messages: {
    ch: require('./ch.js'), // 引入语言文件
    en: require('./en.js')
  }
})
export default i18n
