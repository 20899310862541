
import Dialog from 'quasar/src/plugins/Dialog.js';
import i18n from '../language'
import { getOrder } from '../api/my'
import store from '@/store'
import router from '@/router'

const errorHandle = (err) => {
  if (err.config.url === '/common/shipping.ashx' && err.data.status === -1001) {
    Dialog.create({
      title: i18n.global.locale === 'en' ? 'TIP' : '提示',
      message: i18n.global.locale === 'en' ? err.data.contenten : err.data.content,
      cancel: true,
      ok: i18n.global.locale === 'en' ? 'CANCEL' : '继续支付',
      cancel: i18n.global.locale === 'en' ? 'PAYMENT' : '取消支付'
    }).onOk(() => {
      getOrder({
        type: 0,
        status: 1,
        mark: err.data.oguid,
        isorder: 1
      }).then(res => {
        if (res.status === 1) {
          const list = res.list[0].list
          store.commit('setChichekInfo', JSON.stringify(list))
          router.push({
            path: '/chichek',
            query: {
              integralScale: 1
            }
          })
        }
      })
    }).onCancel(() => {
      getOrder({
        type: 5,
        mark: err.data.oguid,
        ostatus: 1,
      }).then((res) => {
        if (res.status === 1) {
          if (router.currentRoute._value.path === '/cart') {
            router.go(0)
          } else {
            router.push('/cart')
          }
        }
      })
    })
  }
  if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
    Dialog.create({
      title: i18n.global.locale === 'en' ? 'TIP' : '提示',
      message: i18n.global.locale === 'en' ? 'The request timed out. Please check the network' : '请求超时，请检查网络',
    })
  } else if (
    err.message !== undefined &&
    err.message.indexOf('Network Error') !== -1
  ) {
    Dialog.create({
      title: i18n.global.locale === 'en' ? 'TIP' : '提示',
      message: i18n.global.locale === 'en' ? 'Network error, please check the network' : '网络错误，请检查网络'
    })
  }
}

export default errorHandle
