<template>
  <div class="indexCon">
    <div class="blackswiper" v-if="homeDis.length">
      <swiper :autoplay="true">
        <swiper-slide
          v-for="(item, index) in homeDis"
          v-show="item.isopen"
          :key="index"
        >
          <div class="discount_black" @click="golink(item.url)">
            <img class="img" :src="item.picture" />
            <div class="title">
              <span>{{
                $i18n.locale == "en" ? item.titleen : item.title
              }}</span>
              <span class="sub">{{
                $i18n.locale == "en" ? item.contentsen : item.contents
              }}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="swiper">
      <swiper
        :slides-per-view="1"
        :autoplay="true"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <div class="img" @click="golink(item.urllink)">
            <q-img :src="item.bannerpicture" :ratio="3 / 1" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <template v-for="item in page" :key="item.id">
      <component :is="components.get(item.h_type)" :datas="item"></component>
    </template>
  </div>
</template>

<script>
import { components } from '.'
import { homePage, homediscount } from '../../api/common'
import { swiperConfig } from '@/plugins/swiperConfig'

const { Swiper, SwiperSlide, SwiperCore, Pagination, Autoplay } = swiperConfig()
SwiperCore.use([Pagination, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      page: [],
      banner: [],
      homeDis: [],
      timer: null
    }
  },
  methods: {
    // 获取首页
    async getHomePage () {
      // 如果是女孩id 则跳到儿童首页
      const type = this.$store.state.pageType.id === '4' ? 3 : this.$store.state.pageType.id
      const res = await homePage({
        type: type
      })
      if (res.status === 1) {
        this.banner = res.banner
        this.page = res.homepage
      }
    },
    // 获取广告
    async getHomeDis () {
      const type = this.$store.state.pageType.id === '4' ? 3 : this.$store.state.pageType.id
      const res = await homediscount({
        action: 'selectlist',
        type: type
      })
      this.homeDis = res.homediscount
    },
    golink (url) {
      if (url) {
        window.location.href = url
      }
    }
  },
  computed: {
    PageId () {
      return this.$store.state.pageType.id
    }
  },
  watch: {
    // 监听pageType变化，否则页面类别不会刷新
    PageId: {
      handler (e) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getHomePage()
          this.getHomeDis()
        }, 100)
      },
      immediate: true
    }
  },
  setup () {
    return {
      components
    }
  }
}
</script>

<style lang="scss" scoped>
.discount_black {
  width: 100%;
  background-color: #000;
  color: #fff;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .img {
    height: 50px;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    font-size: 16px;
  }
}
.swiper {
  margin-bottom: 50px;
  position: relative;
  --swiper-pagination-color: #fff;
  .img {
    position: relative;
    cursor: pointer;
  }
}
</style>
