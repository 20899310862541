<template>
  <div class="noticebar-cont" v-if="vipdis.length">
    {{ vipdis[0].introduce }}
  </div>
  <div class="headCon pucontainer">
    <div class="h_left">
      <span>Language |</span>
      <q-btn-dropdown menu-anchor="bottom middle" menu-self="top middle" dense flat :label="$t('header_menu.language')">
        <q-list dense>
          <q-item clickable v-close-popup @click="languageChange('ch')">
            <q-item-section>
              <q-item-label>CN</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="languageChange('en')">
            <q-item-section>
              <q-item-label>EN</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <div style="display: flex;align-items: center;" @mouseover="menuMouseover" @mouseout="menuMouseout">
        <img class="uk" src="@/assets/cn.png">
        <span style="padding-left: 5px;">{{ $t('common.cn_site') }}</span>
        <q-menu v-model="menuShow" @mouseover="menuMouseover" @mouseout="menuMouseout">
          <q-list dense>
            <q-item clickable v-close-popup>
              <q-item-section @click="goUk">
                <img class="uk" src="@/assets/uk.png">
                <q-tooltip anchor="center right" self="center left">
                  {{ $t('common.click_switch') }}{{ $t('common.uk_site') }}
                </q-tooltip>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
    </div>
    <div class="h_center" v-if="isnav">{{ $t("header_menu.fees") }}</div>
    <div class="h_right" v-if="isnav">
      <div class="myintegral" v-if="islogin" @click="$router.push('/my/points')">
        <span>{{ $t("header_menu.member") }}</span>
        <span class="sline">|</span>
        <span class="blod">{{ format(userInfo.integral) }}&nbsp;{{
            $t("header_menu.integral")
        }}</span>
      </div>
      <div v-if="!islogin" style="cursor: pointer" @click="$router.push('/login')">
        <span class="iconfont icon-yonghu"></span>
        {{ $t("header_menu.users") }}
      </div>
      <div v-else>
        <q-btn-dropdown menu-anchor="bottom middle" menu-self="top middle" dense icon="iconfont icon-yonghu" flat
          :label="userInfo.username">
          <q-list>
            <q-item dense clickable v-close-popup v-for="(item, index) in navarr" :key="index" :to="item.url">
              <q-item-section>
                <q-item-label>{{ item.name }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator spaced />
            <q-item dense clickable v-close-popup @click="loginOut">
              <q-item-section>
                <q-item-label>
                  <q-icon size="20px" name="iconfont icon-dengchu" />
                  {{ $t("common.out") }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <div class="info">
        <div @click="$router.push('/collection')">
          <span class="iconfont icon-like"></span>
          <span v-if="islogin" class="red">{{ userInfo.wishcount }}</span>
        </div>
        <div @click="$router.push('/cart')">
          <span class="iconfont icon-gouwuche"></span>
          <span v-if="islogin" class="red">{{ userInfo.shoppingcount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upDatedHead } from '@/common/upDatedHead'
import store from '@/store'
import { loginOut } from '@/api/login'
import _ from 'lodash'

export default {
  props: {
    isnav: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      navarr: _.cloneDeep(store.state.navList),
      menuShow: false,
      menuTimer: null
    }
  },
  computed: {
    islogin () {
      return store.state.isLogin
    },
    userInfo () {
      return store.state.userInfo
    },
    vipdis () {
      return store.state.VIPdis
    },
    format () {
      return function (val) {
        const num = parseInt(val)
          .toString()
          .replace(/\B(?=(\d{3})+\b)/g, ',')
        return num
      }
    }
  },
  watch: {
    userInfo: {
      handler () {
        this.navarr = JSON.parse(JSON.stringify(store.state.navList))
        if (this.userInfo.usertype === '1') {
          this.navarr.push({
            name: this.$t('navList.sale'),
            url: '/my/salesRecord'
          })
          this.navarr.push({
            name: this.$t('navList.invite'),
            url: '/my/inviteRecord'
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    loginOut () {
      this.$q
        .dialog({
          message: this.$t('common.confirm_out'),
          cancel: true,
          persistent: true
        })
        .onOk(async () => {
          const res = await loginOut()
          if (res.status === 1) {
            upDatedHead()
            this.$store.commit('setIsLogin', false)
            this.$router.push('/login')
          }
        })
    },
    languageChange (e) {
      if (e === this.$i18n.locale) {
        return
      }
      localStorage.setItem('language', e)
      this.$router.go(0)
    },
    goUk () {
      window.open(window.location.origin + '/uk')
    },
    menuMouseover () {
      clearTimeout(this.menuTimer)
      this.menuShow = true
    },
    menuMouseout () {
      this.menuTimer = setTimeout(() => {
        this.menuShow = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.noticebar-cont {
  background-color: #c10000;
  text-align: center;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
}

.headCon {
  position: relative;
  max-width: 1500px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  color: #000;

  .h_left {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .h_center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .h_right {
    display: flex;
    align-items: center;
  }

  .h_right>div {
    margin-right: 10px;
    color: #000;
    display: flex;
    align-items: center;
  }

  .h_right {
    .info {
      &>div {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .red {
          display: inline-block;
          background-color: red;
          color: #fff;
          border-radius: 50%;
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 10px;
          width: 18px;
          height: 18px;
          text-align: center;
          line-height: 18px;
          transform: scale(0.8);
          transform-origin: right bottom;
        }
      }
    }

    .myintegral {
      margin-right: 20px;
      cursor: pointer;

      span {
        font-size: 14px;
      }

      .sline {
        margin: 0 5px;
      }
    }
  }

  .h_right>div span {
    font-size: 20px;
  }

  .h_right>div span.icon-like {
    font-size: 24px;
    margin-right: 5px;
  }

  .h_right>div:nth-of-type(1) {
    min-width: 100px;
  }
}
</style>
<style>
.uk {
  width: 20px;
  height: 13.5px;
  cursor: pointer;
}
</style>
