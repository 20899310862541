module.exports = {
  // 公共
  common: {
    resetting: 'resetting',
    men: 'MEN',
    women: 'WOMEN',
    kids: 'KIDS',
    sale: 'SALE',
    home: 'HOME',
    boy: 'BOY',
    girl: 'GIRL',
    all: 'ALL',
    collections: 'COLLECTIONS',
    brands: 'BRANDS',
    categorys: 'CATEGORIES',
    view_all: 'VIEW ALL',
    back: 'BACK',
    submit: 'Submit',
    out: 'OUT',
    no_search: 'NO RESULT',
    c_shopping: 'CONTINUE SHOPPING',
    tip: 'TIP',
    copy_success: 'Copy successful',
    cant_copy: 'The browser does not support copying',
    go_shopping: 'GO SHOPPING',
    add_wish_success: 'Add collection succeeded!',
    remove_wish_success: 'Remove collection succeeded!',
    confirm_delete: 'Confirm Delete?',
    confirm_out: 'Confirm Out?',
    exchange_success: 'Exchange succeeded!',
    reg_receive: 'Register to receive',
    no_restrictions: 'No restrictions',
    get_now: 'GET IT NOW',
    no_goods: 'This commodity is not in stock yet',
    cn_site: 'CN SITE',
    uk_site: 'UK SITE',
    click_switch: 'CLICK SWITCH '
  },
  // 品牌a-z
  brands_az: {
    like_brands: 'FAVOURITE BRANDS',
    search_brand: 'SEARCH BRANDS',
    no_find: 'NO FIND'
  },
  // 头部菜单
  header_menu: {
    language: 'EN',
    fees: 'Import fees included',
    users: 'MY AREA',
    top_brands: 'TOP BRANDS',
    category: 'BRANDS BY CATEGORY',
    brand_a_z: 'BRANDS A-Z',
    view_brans_all: 'VIEW ALL BRANDS',
    popular: 'POPULAR BRAND LIST',
    integral: 'points',
    member: 'Member Points',
    search_sale: 'SALE',
    search_goods: 'COMMODITY',
    search_brand: 'BRANDS',
    search_cate: 'CATEGORY'
  },
  // 底部菜单
  footer_menu: {
    your_email: 'Your Email',
    about_us: 'ABOUT US',
    service_support: 'SERVICE SUPPORT',
    users: 'MY AREA',
    hours24: 'Open 24/7',
    classify: 'CATEGORY',
    subscribed: 'Sign Up For Updates',
    enter_email: 'Please enter your email',
    email_err: 'Please enter a valid email',
    subscription_succeeded: 'Subscription succeeded',
    exchangesTitle: 'Exchanges and returns',
    TermsTitle: 'Terms & Conditions'
  },
  // 登录或注册
  login_mod: {
    login_t: 'Sign In',
    reg_t: 'REGISTER NOW',
    phone_t: 'MOBILE NUMBER',
    phone_format: 'Please enter a valid phone',
    email_t: 'EMAIL ADDRESS',
    email_format: 'Please enter a valid email',
    pwd_t: 'PASSWORD',
    keep_signed: 'KEEP ME SIGNED IN',
    forgot_pwd: 'Forgot Password?',
    ro_t: 'SIGN IN WITH',
    go_login: 'Go Sign In',
    name: 'Name',
    name_input: 'Please fill in your name',
    verification_code: 'VERIFICATION CODE',
    send_verification_code: 'SEND VERIFICATION CODE',
    enter_phone: 'Please enter your phone',
    enter_email: 'Please enter your email',
    enter_pwd: 'Please enter your password',
    enter_verification_code: 'Please enter verification code',
    invitecode: 'INVITATION CODE',
    re_pwd: 'RE-TYPE PASSWORD',
    reset_pwd: 'RESET PASSWORD',
    no_one_pwd: 'Passwords does not match!',
    change_pwd: 'CHANGE PASSWORD',
    p_out: 'Please log out',
    old_phone_t: 'OLD PHONE NUMBER',
    new_phone_t: 'NEW PHONE NUMBER',
    old_phone_t_err: 'old phone mumber error',
    old_email_t_err: 'old email error',
    submit: 'SUBMIT',
    reg_success: 'login was successful',
    pwd_format: 'At least 8-16 characters, 1 lowercase letter and 1 number'
  },
  // 个人中心导航
  navList: {
    account: 'MY ACCOUNT',
    orders: 'MY ORDERS',
    return: 'MY RETURNS',
    vip: 'LOYALTY CENTRE',
    address: 'ADDRESS BOOK',
    sale: 'SALES RECORD',
    invite: 'INVITATION RECORD'
  },
  account: {
    wechart: 'WECHAT',
    invitecode: 'INVITATION CODE',
    name: 'NAME',
    gender: 'GENDER',
    birthday: 'BIRTHDAY',
    pwd: 'PASSWORD',
    phone: 'PHONE',
    email: 'EMAIL ADDRESS',
    save: 'SAVE',
    male: 'MALE',
    female: 'FEMALE',
    close: 'CLOSE',
    bind_phone: 'BIND PHONE',
    change_phone: 'CHANGE PHONE',
    verification_code: 'VERIFICATION CODE',
    send_verification_code: 'SEND VERIFICATION CODE',
    set_email: 'SET EMAIL'
  },
  orders: {
    change: 'CHANGE',
    all: 'ALL',
    pending: 'PENDING',
    shipped: 'SHIPPED',
    completed: 'COMPLETED',
    no_order: 'NO ORDERS FOUND',
    search_order: 'SEARCH ORDERS',
    date: 'DATE',
    order_number: 'ORDER N°',
    shipping_address: 'SHIPPING ADDRESS',
    voucher: 'VOUCHER',
    s_pay: 'PAYMENT',
    y_pay: 'ACCOUNTS PAYABLEX',
    b_pay: 'BALANCE PAYMENT',
    refund: 'REFUND',
    b_refund: 'BALANCE REFUND',
    look_wl: 'VIEW LOGISTICS',
    look_xj: 'VIEW DETAILS',
    apply_retrun: 'REQUEST RETURN',
    name: 'NAME:',
    phone: 'PHONE:',
    email: 'EMAIL:',
    address: 'ADDRESS:',
    post_code: 'POST CODE:',
    status: 'STATUS',
    product: 'PRODUCT',
    price: 'PRICE',
    num: 'QUANTITY',
    total: 'TOTAL',
    color: 'COLOR:',
    size: 'SIZE:',
    g_code: 'CODE:',
    confirm_receipt: 'CONFIRM RECEIPT',
    total_discount: 'TOTAL DISCOUNT:',
    before_exchange: 'GOODS BEFORE EXCHANGE',
    look_before_exchange: 'LOOK GOODS BEFORE EXCHANGE',
    goods_received_successfully: 'Goods received successfully',
    not_used: 'NOT USED',
    reason: 'REASON',
    normal_goods: 'FULL PRICE ITEM',
    buy_user: 'PURCHASE USER',
    select_return: 'SELECT ITEMS',
    return_fail: 'RETURN NOT ACCEPTED',
    return_fail2: 'REFUND NOT ACCEPTED',
    exchange_fail: 'EXCHANGE NOT ACCEPTED',
    return_examine: 'Return is being processed',
    return_examine2: 'REFUND is being processed',
    exchange_examine: 'Exchange is being processed',
    exchange_complete: 'EXCHANGE COMPLETE',
    return_complete: 'RETURN COMPLETE',
    return_complete2: 'REFUND COMPLETE',
    wait_customer_service: 'Your request is under review',
    exchange_goods: 'EXCHANGE COMPLETE',
    color2: 'COLOR',
    size2: 'SIZE',
    successful_trade: 'ORDER COMPLETED',
    p_select_color_size: 'Please select the color and size for return',
    select_confirm_receipt: 'Please select whether to receive the goods',
    delivered: 'Delivered',
    contact_your: 'We will be in contact soon. Thank you for your patience',
    return_received: 'Return received',
    return_not_received: 'Return not received',
    return_button: 'RETURN OR EXCHANGE',
    return_mode: 'MODE',
    return_reason: 'REASON',
    return_title: 'RETURN',
    product_title: 'PRODUCT',
    is_received: 'Have you received the goods?',
    full_refund: 'Full refund',
    change_color_size: 'Change color or size',
    wrong_size: 'Wrong size',
    wrong_color: 'Wrong color',
    dislike: 'Dislike',
    defects: 'Defects',
    other: 'Other reasons',
    downInvoice: 'DOWNLOAD INVOICE'
  },
  vip: {
    user_id: 'User ID:',
    wvipjlj: 'pending rewards',
    my_vipjlj: 'MY REWARDS',
    vipjlj: 'REWARDS',
    user_id: 'User ID:',
    order_date: 'Order Date:',
    posting_date: 'Posting Date:',
    abalance: 'balance',
    wbalance: 'pending balance',
    my_points: 'MY LOYALTY POINTS',
    my_balance: 'MY BALANCE',
    my_rewards: 'MY REWARDS',
    points_changes: 'REDEEM MY LOYALTY POINTS',
    questions: 'FREQUENTLY ASKED QUESTIONS',
    active: 'PROMOTIONS',
    date: 'DATE',
    points: 'POINTS',
    status: 'STATUS',
    tip1: 'Here you can see a detailed list of how you earned your reward points.\nPoints from purchases will become available for use after 35 days, to allow for the Return Period. Points will expire on the last day of the month.',
    rank: 'Level',
    your_rewards: 'YOUR REWARDS',
    rewards: 'REWARDS',
    rewards_code: 'REWARDS CODE',
    effective_time: 'EFFECTIVE TIME',
    shop_order: 'ORDER:',
    exchange: 'REDEEM',
    not_available: 'NOT AVAILABLE',
    introduce: 'DETAILS',
    copy: 'COPY',
    used: 'USED',
    no_code: 'NO CODE',
    look_use_prize: 'VIEW USED',
    hide: 'HIDE',
    no_prize: 'No prizes',
    redeem_points: 'REDEEM YOUR REWARD POINTS',
    redeem_points_detail: 'Use your points for exclusive rewards, luxury experiences and future orders at ANNILUX.COM',
    credit: 'CREDIT',
    use_credit: 'REDEEM CREDIT TO USE ON FUTURE PURCHASES',
    see_option: 'SEE THE OPTIONS',
    privilege_card: 'PRIVILEGE CARD',
    use_privilege_card: 'REDEEM POINTS TOWARD PRIVILEGE CARD',
    free_shipping: 'FREE SHIPPING',
    use_free_shipping: 'REDEEM POINTS FOR 12 MONTHS OF FREE SHIPPING',
    points_ex: 'POINTS',
    discover_more: 'DISCOVER MORE',
    discount: '0%',
    prize_detail: 'Here you can find an updated list of your rewards.',
    expiring_points: 'Expiring Points',
    exchange_succeeded: 'Exchange succeeded',
    waiting_points: 'PENDING POINTS',
    available_points: 'AVAILABLE POINTS',
    about_expire: 'About to expire',
    no_goods: 'This site does not have this product temporarily'
  },
  address: {
    my_address: 'MY ADDRESS',
    new_address: 'ADD NEW ADDRESS',
    edit: 'EDIT ADDRESS',
    delet: 'REMOVE',
    name: 'NAME:',
    city: 'COUNTRY/REGION:',
    contact: 'CONTACT DETAILS:',
    post: 'POST CODE:',
    email_address: 'EMAIL ADDRESS:',
    ship_to: 'SHIP TO:',
    address_info: 'ADDRESS:',
    detail_address: 'DETAILED ADDRESS:',
    phone: 'PHONE NUMBER:',
    set_default: 'SET AS DEFAULT SHIPPING ADDRESS',
    save: 'SAVE',
    back_order: 'BACK',
    add_tip: 'NO ADDRESS',
    show_all: 'SHOW_ALL',
    hide_all: 'HIDE_ALL',
    default_address: 'DEFAULT SHIPPING ADDRESS',
    select_address: 'SELECTED SHIPPING ADDRESS',
    enter_address: 'Please enter your address',
    enter_name: 'Please enter your name',
    enter_email: 'Please enter your email',
    enter_region: 'Please select region',
    enter_postcode: 'Please enter your post code',
    enter_phone: 'Please enter your phone',
    phone_err: 'Please enter a valid phone',
    email_err: 'Please enter a valid email',
    add: 'ADD',
    change_success: 'Successfully',
    distributionCN: 'China',
    distributionAB: 'Abroad',
    country: 'COUNTRY',
    citys: 'CITY',
    enter_country: 'Please enter country',
    enter_citys: 'Please enter city'
  },
  invite: {
    invite_users: 'INVITE USERS',
    time: 'REGISTRATION DATE/TIME'
  },
  wish: {
    wish_title: 'WISHLIST',
    add_cart: 'ADD TO SHOPPING CART',
    no_wish: 'WISHLIST IS EMPTY',
    color: 'COLOR',
    size: 'SIZE',
    price: 'PRICE:',
    ok: 'OK',
    cancel: 'CANCEL',
    withdrawn: 'WITHDRAWN',
    no_stock: 'NO STOCK',
    confirm_delete: 'Confirm Delete?',
    add_success: 'SUCCESSFULLY ADDED TO SHOPPING CART'
  },
  cart: {
    Total_Product: 'TOTAL PRODUCT',
    Switch_Sites: 'SWITCH SITES',
    isUseBalance: 'Balance:',
    cart_title: 'SHOPPING CART',
    sum: 'SUM',
    total: 'TOTAL',
    remove: 'REMOVE',
    select: 'ALL',
    buy: 'CONFIRM',
    goods_color: 'COLOR',
    goods_code: 'CODE',
    goods_size: 'SIZE',
    goods_price: 'PRICE',
    check_title: 'ORDERS',
    play: 'PURCHASE',
    c_play: 'PAY',
    num: '',
    nums: 'QUANTITY',
    order_number: 'ORDER NUMBER:',
    no_select: 'Please Select',
    c_delete: 'Confirm Delete?',
    show_all: 'SHOW ALL',
    hide_all: 'HIDE ALL',
    withdrawn: 'WITHDRAWN',
    no_stock: 'NO STOCK',
    get: 'GET',
    point: 'POINTS',
    confirm: 'Confirm',
    enter_code: 'Please enter code',
    use_success: 'Successful',
    enter_address: 'Please enter address',
    no_goods: 'YOUR SHOPPING BAG IS EMPTY',
    pay_tip: 'Please pay immediately',
    reduction: 'reduction',
    discount_code: 'Discount code',
    credit: 'credit',
    dis_j: 'discount',
    please_address: 'Please add an address',
    coupontitle: 'Distribution costs',
    coupontitles: 'Preferential fee',
    paid: 'Paid',
    account_pay: 'Accounts payable',
    s_pay_price: 'Pay Amount',
    y_pay_price: 'Accounts payable',
    Use_balance: 'Use balance'
  },
  goods_list: {
    mode: 'SORT BY',
    mode_all: 'ALL',
    new: 'JUST IN',
    low_price: 'LOW PRICE',
    high_price: 'HIGH PRICE',
    all: 'ALL',
    price: 'PRICE',
    cate_all: 'ALL CATEGORY',
    cate_sub: 'CATEGORY',
    look_all: 'ALL',
    brands: 'BRANDS',
    search: 'SEARCH',
    more: 'MORE',
    all_remove: 'REMOVE ALL',
    tip_low: 'Not lower than the lowest price',
    tip_top: 'Not higher than the maximum price'
  },
  goods: {
    color: 'COLOUR',
    size: 'SIZE',
    add_cart: 'ADD TO SHOPPING CART',
    add_wish: 'ADD TO WISHLIST',
    copy_link: 'COPY LINK',
    recommend_goods: 'Recommended Products',
    recommend_brands: 'Recommended Brands',
    code: 'CODE:',
    product_detail: 'PRODUCT INFORMATION',
    share_to: 'SHARE TO',
    brand: 'BRAND',
    goods: 'PRODUCT',
    price: 'PRICE',
    add_cart_suss: 'SUCCESSFULLY ADDED TO SHOPPING CART',
    go_shopping: 'CONTINUE SHOPPING',
    go_pay: 'CHECKOUT',
    select_color: 'SELECT COLOR',
    select_color_size: 'SELECT SIZE',
    wechat: 'WeChat',
    weibo: 'Weibo',
    get: 'GET',
    withdrawn: 'WITHDRAWN',
    no_stock: 'NO STOCK',
    points: 'POINTS'
  },
  payment_info: {
    pay_success: 'PAYMENT SUCCESSFUL',
    order_detail: 'ORDER DETAILS',
    back_cart: 'BACK',
    order_info: 'ORDER INFORMATION',
    order_id: 'ORDER NUMBER:',
    pay_mode: 'PAYMENT METHOD:',
    create_time: 'CREATE TIME:',
    pay_time: 'PAYMENT TIME:',
    shop_address: 'SHOPPING ADDRESS:',
    name: 'NAME:',
    phone: 'PHONE:',
    email: 'EMAIL:'
  }
}
