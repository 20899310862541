const global = {
  state: {
    requestNumber: 0,
    isStopLoading: false,
    currency: process.env.VUE_APP_CURRENCY || '￥',
    channel: 1, //渠道{中文站：1；英文站：2；英国站：3}
  },
  mutations: {
    startLoading(state) {
      state.requestNumber++
    },
    finishLoading(state) {
      setTimeout(() => {
        state.requestNumber--
        state.isStopLoading = false
      }, 1000)
    },
    setIsStopLoading(state) {
      state.isStopLoading = true
    }
  },
  getters: {
    isLoading: (state) => {
      return state.requestNumber > 0
    }
  }
}

export default global
