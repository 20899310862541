import { createRouter, createWebHistory } from 'vue-router'
import config from '@/config'

import routes from './routes'
import { statistics } from '@/api/common'

import store from '@/store'
import Notify from 'quasar/src/plugins/Notify.js';

const router = createRouter({
  history: createWebHistory(config.publicPath),
  ...routes
})

router.beforeEach((to, from, next) => {
  // 判断登录是否超时
  const isLogin = JSON.parse(localStorage.getItem('isLogin') || false)
  store.commit('setIsLogin', isLogin)
  if (to.meta.islogin) {
    if (!isLogin) {
      router.push('/login')
      Notify.create('尚未登录，请登录后操作')
    }
  }
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || false)
  store.commit('setUserInfo', userInfo)
  next()
  if (from.name === 'Goodslist' && to.name === 'Goodslist') {
    return
  } else if (from.name === 'Goods' && to.name === 'Goods') {
    return
  } else {
    statistics()
  }
  window.scrollTo(0, 0)
})

export default router
