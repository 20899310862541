import axios from '@/utils/request'
import qs from 'qs'

/* 获取首页 */
export const homePage = (param) => {
  return axios.post(
    '/common/homepage2.ashx',
    qs.stringify({
      ...param
    })
  )
}

/* 首页广告 */
export const homediscount = (param) => {
  return axios.post(
    '/common/homediscount.ashx',
    qs.stringify({
      ...param
    })
  )
}

/* 订阅邮箱 */
export const mailbox = (params) => {
  return axios.post(
    '/common/mailbox.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 获取导航信息 */
export const currency2 = (params) => {
  return axios.post(
    '/common/currency2.ashx',
    qs.stringify({
      ...params
    })
  )
}

/* 关注品牌 */
export const focusBrand = (guid) => {
  return axios.post(
    '/common/FocusBrand.ashx',
    qs.stringify({
      action: 'FocusBrand',
      ...guid
    })
  )
}

// 统计
export const statistics = (params) => {
  if (process.env.NODE_ENV !== 'production') return
  return axios.post(
    '/common/statistics.ashx',
    qs.stringify({
      channel: localStorage.getItem('language') === 'en' ? 2 : 1,
      terminal: 3,
      ...params
    })
  )
}
